import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Login from './components/Login';
import TaskList from './components/TaskList';
import TaskDetail from './components/TaskDetail';
import axiosInstance from './api/axiosInstance';
import { API_URL } from './config';
import PrivateRoute from './components/PrivateRoute';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: { main: '#1976d2' },
    secondary: { main: '#dc004e' },
    background: { default: '#f5f5f5', paper: '#ffffff' },
  },
});

function App() {
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    fetchTasks();
  }, []);

  const fetchTasks = async () => {
    try {
      const response = await axiosInstance.get(`${API_URL}/tasks`);
      setTasks(response.data);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };

  const handleUpdateTask = async (id, updatedFields) => {
    try {
      const response = await axiosInstance.put(`${API_URL}/tasks/${id}`, updatedFields);
      setTasks(prevTasks => prevTasks.map(task => 
        task.id === id ? {...task, ...response.data} : task
      ));
    } catch (error) {
      console.error("Error updating task:", error);
    }
  };

  const handleAddSiblingTask = async (afterTaskId) => {
    console.log("Adding sibling task after:", afterTaskId);
    try {
      const parentTask = tasks.find(t => t.id === afterTaskId);
      const newTaskData = {
        title: '', 
        notes: '',
        parent_ids: parentTask ? parentTask.parent_ids : []
      };
      console.log("Sending new task data:", newTaskData);
      const response = await axiosInstance.post(`${API_URL}/tasks`, newTaskData);
      console.log('Server response:', response.data);
      setTasks(prevTasks => [...prevTasks, response.data]);
    } catch (error) {
      console.error("Error adding sibling task:", error.response?.data || error.message);
    }
  };


  const handleAddSubtask = async (parentId) => {
    try {
      const newTaskData = {
        title: '',
        parent_ids: [parentId],
      };
      const response = await axiosInstance.post(`${API_URL}/tasks`, newTaskData);
      setTasks(prevTasks => [...prevTasks, response.data]);
    } catch (error) {
      console.error("Error adding subtask:", error);
    }
  };

  const handleAIBreakdown = async (id) => {
    try {
      const response = await axiosInstance.post(`${API_URL}/tasks/${id}/ai-breakdown`);
      setTasks(prevTasks => {
        const updatedTasks = prevTasks.map(task => 
          task.id === id ? {...task, ...response.data[0]} : task
        );
        return [...updatedTasks, ...response.data.slice(1)];
      });
    } catch (error) {
      console.error("Error performing AI breakdown:", error);
    }
  };

  const handleAIExecute = async (id) => {
    try {
      const response = await axiosInstance.post(`${API_URL}/tasks/${id}/ai-execute`);
      setTasks(prevTasks => prevTasks.map(task => 
        task.id === id ? {...task, ...response.data} : task
      ));
    } catch (error) {
      console.error("Error executing task with AI:", error);
    }
  };

  const handleDeleteTask = async (id) => {
    try {
      await axiosInstance.delete(`${API_URL}/tasks/${id}`);
      setTasks(prevTasks => prevTasks.filter(task => task.id !== id));
    } catch (error) {
      console.error("Error deleting task:", error);
    }
  };

  const handleLogout = () => {
    // Clear the token from localStorage
    localStorage.removeItem('token');
    // Clear any user-related state if you have any
    // For example: setUser(null);
    // Optionally, you can also clear the tasks
    setTasks([]);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route 
            path="/tasks" 
            element={
              <PrivateRoute>
                <TaskList 
                  tasks={tasks} 
                  onUpdateTask={handleUpdateTask}
                  onAddSiblingTask={handleAddSiblingTask}
                  onAddSubtask={handleAddSubtask}
                  onAIBreakdown={handleAIBreakdown}
                  onAIExecute={handleAIExecute}
                  onDelete={handleDeleteTask}
                  onLogout={handleLogout}  // Add this line
                />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/tasks/:taskId" 
            element={
              <PrivateRoute>
                <TaskDetail 
                  tasks={tasks}
                  onUpdateTask={handleUpdateTask}
                  onAddSiblingTask={handleAddSiblingTask}
                  onAddSubtask={handleAddSubtask}
                  onAIBreakdown={handleAIBreakdown}
                  onAIExecute={handleAIExecute}
                  onDelete={handleDeleteTask}
                  onLogout={handleLogout}  // Add this line
                />
              </PrivateRoute>
            } 
          />
          <Route path="/" element={<Navigate to="/tasks" />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;