import React, { useState, useRef } from 'react';
import { ListItem, ListItemIcon, Checkbox, ListItemText, IconButton, Box, Tooltip, TextField, ClickAwayListener } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ExpandLess, ExpandMore, Add, Delete, AddCircleOutline, ExpandMore as ExpandMoreIcon, Psychology, PlayArrow } from '@mui/icons-material';

const StyledListItem = styled(ListItem)(({ theme, depth, isHovered }) => ({
  paddingLeft: `${depth * 20}px`,
  position: 'relative',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  transition: 'border 0.2s',
  border: '1px solid transparent',
  borderColor: isHovered ? theme.palette.primary.main : 'transparent',
  borderRadius: theme.shape.borderRadius,
}));

const ActionIconsContainer = styled(Box)(({ theme, isVisible }) => ({
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  right: theme.spacing(1),
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: 2,
  opacity: isVisible ? 1 : 0,
  transition: 'opacity 0.2s',
  backgroundColor: `${theme.palette.background.paper}CC`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(0.5),
}));

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  '& .MuiListItemText-primary': {
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
  },
  zIndex: 1,
  position: 'relative',
}));

function TaskItem({ 
  task, 
  depth, 
  onToggleExpand, 
  onToggleCompletion, 
  onAddSubtask, 
  onAddSiblingTask,
  onExpandDetails,
  onAIBreakdown, 
  onAIExecute, 
  onDelete, 
  onUpdate, 
  dragHandleProps
}) {
  const [editingTitle, setEditingTitle] = useState(false);
  const [editedTitle, setEditedTitle] = useState(task.title);
  const titleInputRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);

  const handleTitleClick = () => {
    setEditingTitle(true);
  };

  const handleTitleSave = () => {
    if (editedTitle.trim() !== '') {
      onUpdate(task.id, { title: editedTitle });
      setEditingTitle(false);
    }
  };

  const handleTitleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleTitleSave();
    }
  };

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <div style={{ marginLeft: `${depth * 20}px` }}>
      <StyledListItem
        depth={depth}
        {...dragHandleProps}
        isHovered={isHovered}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Box display="flex" width="100%" alignItems="center">
          <Box display="flex" alignItems="center" mr={1}>
            <Box width={28} display="flex" justifyContent="center" flexShrink={0}>
              {task.child_ids && task.child_ids.length > 0 && (
                <Tooltip title={task.expanded ? "Collapse task list" : "Expand task list"}>
                  <IconButton onClick={() => onToggleExpand(task.id)} size="small">
                    {task.expanded ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                </Tooltip>
              )}
            </Box>
            <Checkbox
              edge="start"
              checked={task.completed}
              onChange={() => onToggleCompletion(task.id)}
            />
          </Box>
          <Box flexGrow={1} overflow="hidden" display="flex" alignItems="center">
            {editingTitle ? (
              <ClickAwayListener onClickAway={handleTitleSave}>
                <TextField
                  value={editedTitle}
                  onChange={(e) => setEditedTitle(e.target.value)}
                  onKeyDown={handleTitleKeyDown}
                  inputRef={titleInputRef}
                  fullWidth
                  variant="standard"
                  autoFocus
                  placeholder="Add task title"
                  multiline
                />
              </ClickAwayListener>
            ) : (
              <StyledListItemText 
                primary={task.title || 'Add task title'} 
                onClick={handleTitleClick}
                style={{ 
                  textDecoration: task.completed ? 'line-through' : 'none',
                  cursor: 'pointer',
                }}
              />
            )}
          </Box>
        </Box>
        <ActionIconsContainer isVisible={isHovered}>
          <Tooltip title="Add sibling task">
            <IconButton onClick={() => onAddSiblingTask(task.id)}>
              <Add />
            </IconButton>
          </Tooltip>
          <Tooltip title="Add subtask">
            <IconButton onClick={() => onAddSubtask(task.id)}>
              <AddCircleOutline />
            </IconButton>
          </Tooltip>
          <Tooltip title="Expand task details">
            <IconButton onClick={() => onExpandDetails(task)}>
              <ExpandMoreIcon />
            </IconButton>
          </Tooltip>
        </ActionIconsContainer>
      </StyledListItem>
    </div>
  );
}

export default TaskItem;