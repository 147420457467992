import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { 
  Container, TextField, Button, Typography, Box, Paper, 
  Checkbox, FormControlLabel, Link, InputAdornment
} from '@mui/material';
import { styled } from '@mui/system';
import { Person, Lock, Error } from '@mui/icons-material';
import { API_URL } from '../config';

const StyledContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(2),
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  maxWidth: '400px',
  width: '100%',
  boxShadow: theme.shadows[3],
  backgroundColor: theme.palette.background.paper,
}));

const StyledForm = styled('form')(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(1),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(3, 0, 2),
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1.5, 0),
}));

const ErrorBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  padding: theme.spacing(1),
  backgroundColor: theme.palette.error.light,
  color: theme.palette.error.dark,
  borderRadius: theme.shape.borderRadius,
}));

const StyledInputAdornment = styled(InputAdornment)(({ theme }) => ({
  '& .MuiSvgIcon-root': {
    color: theme.palette.text.secondary,
  },
}));

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append('username', username);
      formData.append('password', password);

      const response = await axios.post(`${API_URL}/token`, formData, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      });

      if (response.status === 200) {
        localStorage.setItem('token', response.data.access_token);
        navigate('/tasks');
      }
    } catch (error) {
      console.error('Login error:', error);
      setError('Invalid username or password');
    }
  };

  return (
    <StyledContainer maxWidth={false}>
      <StyledPaper elevation={6}>
        <Typography variant="h5" component="h1" sx={{ fontWeight: 'bold', lineHeight: 1 }}>
          <Box component="span">tod</Box>
          <Box component="span" sx={{ color: 'primary.main' }}>AI</Box>
        </Typography>
        <Typography variant="subtitle2" sx={{ color: 'text.secondary', mt: 0.5 }}>
          not tomorrow
        </Typography>
        
        {error && (
          <ErrorBox>
            <Error fontSize="small" style={{ marginRight: 8 }} />
            <Typography variant="body2">{error}</Typography>
          </ErrorBox>
        )}
        
        <StyledForm onSubmit={handleLogin}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            InputProps={{
              startAdornment: (
                <StyledInputAdornment position="start">
                  <Person />
                </StyledInputAdornment>
              ),
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              startAdornment: (
                <StyledInputAdornment position="start">
                  <Lock />
                </StyledInputAdornment>
              ),
            }}
          />
          <Box display="flex" justifyContent="space-between" alignItems="center" mt={1}>
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Link href="#" variant="body2">
              Forgot password?
            </Link>
          </Box>
          <StyledButton
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            Login
          </StyledButton>
        </StyledForm>
        
        <Box mt={2}>
          <Typography variant="body2" align="center">
            Don't have an account?{' '}
            <Link href="#" variant="body2">
              Sign up
            </Link>
          </Typography>
        </Box>
      </StyledPaper>
    </StyledContainer>
  );
};

export default Login;