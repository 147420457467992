import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Drawer, List, ListItemButton, ListItemText, IconButton, Divider } from '@mui/material';
import { Menu, Home, Settings, ExitToApp } from '@mui/icons-material';

function DrawerMenu({ onLogout }) {
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleLogout = () => {
    onLogout();
    toggleDrawer(false)();
    navigate('/login');
  };

  return (
    <>
      <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
        <Menu />
      </IconButton>
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
      >
        <List>
          <ListItemButton onClick={() => { navigate('/'); toggleDrawer(false)(); }}>
            <Home sx={{ mr: 2 }} />
            <ListItemText primary="Home" />
          </ListItemButton>
          <ListItemButton onClick={() => { /* Add action here */ toggleDrawer(false)(); }}>
            <Settings sx={{ mr: 2 }} />
            <ListItemText primary="Settings" />
          </ListItemButton>
          <Divider />
          <ListItemButton onClick={handleLogout}>
            <ExitToApp sx={{ mr: 2 }} />
            <ListItemText primary="Logout" />
          </ListItemButton>
        </List>
      </Drawer>
    </>
  );
}

export default DrawerMenu;