import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, TextField, IconButton, Typography, Box, Paper } from '@mui/material';
import { ArrowBack, Save, Add, DeleteOutline, Psychology, PlayArrow } from '@mui/icons-material';
import DrawerMenu from './DrawerMenu';

function TaskDetail({ 
  tasks, 
  onUpdateTask, 
  onAddSiblingTask, 
  onAddSubtask, 
  onAIBreakdown, 
  onAIExecute, 
  onDelete,
  onLogout
}) {
  const { taskId } = useParams();
  const navigate = useNavigate();
  const task = tasks.find(t => t.id === taskId);
  const [title, setTitle] = useState(task ? task.title : '');
  const [notes, setNotes] = useState(task ? task.notes : '');

  if (!task) {
    return <div>Task not found</div>;
  }

  const handleSave = () => {
    onUpdateTask(taskId, { title, notes });
  };

  const handleDelete = () => {
    onDelete(taskId);
    navigate('/tasks');
  };

  return (
    <Container maxWidth="md">
      <DrawerMenu onLogout={onLogout} />
      <Box mt={4}>
        <IconButton onClick={() => navigate('/tasks')} aria-label="back to task list">
          <ArrowBack />
        </IconButton>
        <Typography variant="h4" gutterBottom>
          Task Details
        </Typography>
        <TextField
          fullWidth
          label="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Notes"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          margin="normal"
          multiline
          rows={4}
        />
        <Box mt={2} display="flex" justifyContent="space-between">
          <Box>
            <IconButton onClick={handleSave} color="primary" aria-label="save">
              <Save />
            </IconButton>
            <IconButton onClick={() => onAddSubtask(taskId)} color="secondary" aria-label="add subtask">
              <Add />
            </IconButton>
            <IconButton onClick={() => onAddSiblingTask(taskId)} color="secondary" aria-label="add sibling task">
              <Add />
            </IconButton>
            <IconButton onClick={() => onAIBreakdown(taskId)} color="secondary" aria-label="AI breakdown">
              <Psychology />
            </IconButton>
            <IconButton onClick={() => onAIExecute(taskId)} color="secondary" aria-label="AI execute">
              <PlayArrow />
            </IconButton>
          </Box>
          <IconButton onClick={handleDelete} color="error" aria-label="delete">
            <DeleteOutline />
          </IconButton>
        </Box>
        {task.results && (
          <Paper elevation={3} sx={{ mt: 3, p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Task Results
            </Typography>
            <Typography variant="body1">
              {task.results}
            </Typography>
          </Paper>
        )}
      </Box>
    </Container>
  );
}

export default TaskDetail;