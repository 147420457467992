import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, List, ListItemButton, ListItemText } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { styled } from '@mui/system';
import TaskItem from './TaskItem';
import PlusSignIcon from '../icons/plus-sign-stroke-rounded';
import DrawerMenu from './DrawerMenu';

const StyledList = styled(List)({
  width: '100%',
  backgroundColor: 'background.paper',
});

const LeftAlignedContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '0 16px',
  maxWidth: '100%',
});

function TaskList({ 
  tasks, 
  onUpdateTask, 
  onAddSiblingTask, 
  onAddSubtask, 
  onAIBreakdown, 
  onAIExecute, 
  onDelete,
  onLogout
}) {
  const navigate = useNavigate();

  const handleLogout = () => {
    onLogout();
    navigate('/login');
  };

  const toggleExpand = async (taskId) => {
    try {
      const task = tasks.find(t => t.id === taskId);
      if (task) {
        const updatedTask = { ...task, expanded: !task.expanded };
        await onUpdateTask(taskId, { expanded: updatedTask.expanded });
      }
    } catch (error) {
      console.error("Error toggling expand:", error);
    }
  };

  const onDragEnd = (result) => {
    // Implement drag and drop logic here
  };

  const expandTaskDetails = (task) => {
    navigate(`/tasks/${task.id}`);
  };

  const renderTaskItem = (task, depth = 0) => (
    <React.Fragment key={task.id}>
      <Draggable draggableId={task.id} index={task.sort_order || 0}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={{
              ...provided.draggableProps.style,
              userSelect: 'none',
              margin: '0 0 8px 0',
              background: snapshot.isDragging ? 'lightblue' : 'white',
            }}
          >
            <TaskItem
              task={task}
              depth={depth}
              onToggleExpand={() => toggleExpand(task.id)}
              onToggleCompletion={(id) => onUpdateTask(id, { completed: !task.completed })}
              onAddSubtask={() => onAddSubtask(task.id)}
              onAddSiblingTask={() => onAddSiblingTask(task.id)}
              onExpandDetails={() => expandTaskDetails(task)}
              onAIBreakdown={() => onAIBreakdown(task.id)}
              onAIExecute={() => onAIExecute(task.id)}
              onDelete={() => onDelete(task.id)}
              onUpdate={(id, fields) => onUpdateTask(id, fields)}
              dragHandleProps={provided.dragHandleProps}
            />
          </div>
        )}
      </Draggable>
      {task.expanded && task.child_ids && task.child_ids.length > 0 && (
        <Droppable droppableId={`${task.id}-children`} type="task">
          {(provided) => (
            <List
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{ paddingLeft: `${(depth + 1) * 20}px` }}
            >
              {task.child_ids
                .map(childId => tasks.find(t => t.id === childId))
                .filter(Boolean)
                .sort((a, b) => (a.sort_order || 0) - (b.sort_order || 0))
                .map(childTask => renderTaskItem(childTask, depth + 1))}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      )}
    </React.Fragment>
  );

  const renderSubtasks = (childIds, depth) => (
    <Droppable droppableId={depth.toString()} type="task">
      {(provided) => (
        <List
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          {childIds
            .map(childId => tasks.find(t => t.id === childId))
            .filter(Boolean)
            .sort((a, b) => a.sort_order - b.sort_order)
            .map((childTask, index) => renderTaskItem(childTask, depth, index))}
          {provided.placeholder}
        </List>
      )}
    </Droppable>
  );

  const renderTaskList = () => (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="root" type="task">
        {(provided) => (
          <StyledList
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {tasks
              .filter(task => !task.parent_ids.length)
              .sort((a, b) => (a.sort_order ?? Infinity) - (b.sort_order ?? Infinity))
              .map((task, index) => renderTaskItem(task, 0, index))}
            {provided.placeholder}
            <ListItemButton onClick={() => onAddSiblingTask(null)}>
              <PlusSignIcon />
              <ListItemText primary="Add Task" />
            </ListItemButton>
          </StyledList>
        )}
      </Droppable>
    </DragDropContext>
  );

  return (
    <LeftAlignedContainer maxWidth="md">
      <DrawerMenu onLogout={onLogout} />
      {renderTaskList()}
    </LeftAlignedContainer>
  );
}

export default TaskList;